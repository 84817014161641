import axios from "axios";
export default function authHeader() {
    let user = JSON.parse(localStorage.getItem('user'));
    if(!user){
      user = JSON.parse(sessionStorage.getItem("user"));
  }
    if (user && user.token) {
      axios.defaults.headers.common["token"] = 'Bearer ' + user.token
      return { token: 'Bearer ' + user.token };
    } else {
      return false;
    }
  }