import Drawer from "@mui/material/Drawer";
import { useSelector, useDispatch } from "react-redux";
import { clearDrawer } from "../slices/drawer";
import { clearLoading, setLoading } from "../slices/loader";
import SellerDetails from "./SellerDetails";
import AddCouponDetails from "./AddCouponDetails";
function TemporaryDrawer() {
  const { open, side, data, page } = useSelector((state) => state.drawer);
  const dispatch = useDispatch();
  console.log(page);
  return (
    <Drawer
      anchor={side}
      open={open}
      onClose={() => {
        dispatch(clearDrawer());
      }}
    >
      {page == "sellers" ? (
        <SellerDetails
          dispatch={dispatch}
          clearDrawer={clearDrawer}
          clearLoading={clearLoading}
          setLoading={setLoading}
          data={data}
        ></SellerDetails>
      ) : page == "addCoupon" ? (
        <AddCouponDetails
          dispatch={dispatch}
          clearDrawer={clearDrawer}
          clearLoading={clearLoading}
          setLoading={setLoading}
          data={data}
        ></AddCouponDetails>
      ) : (
        ""
      )}
    </Drawer>
  );
}
export default TemporaryDrawer;
