import axios from "axios";
import AuthHeader from "./auth-header";
AuthHeader()
const getCoupons = async (data) => {
    try{  
        let coupon = await axios.post("/api/coupons/fetch-coupons",data);
        return coupon.data
    }
    catch(e){  

    }
  };
const addCoupon = async (data)=>{  
   return axios.post("/api/coupons/add-coupon",data);
}
  const couponService = {
    getCoupons,
    addCoupon
  };
  export default couponService;