import React, { useState, useEffect  } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../slices/auth";
import { clearMessage } from "../slices/message";
import Card from '@material-tailwind/react/Card';
import { useHistory } from "react-router-dom";
import CardHeader from '@material-tailwind/react/CardHeader';
import CardBody from '@material-tailwind/react/CardBody';
import CardFooter from '@material-tailwind/react/CardFooter';
import H5 from '@material-tailwind/react/Heading5';
import InputIcon from '@material-tailwind/react/InputIcon';
import Checkbox from '@material-tailwind/react/Checkbox';
import Button from '@material-tailwind/react/Button';
import DefaultNavbar from 'components/DefaultNavbar';
import SimpleFooter from 'components/SimpleFooter';
import Page from 'components/login/Page';
import Container from 'components/login/Container';

export default function Login() {
    const history = useHistory();
    const { isLoggedIn,user } = useSelector((state) => state.auth);
    const { message } = useSelector((state) => state.message);
    const dispatch = useDispatch();
    const [userData,setUserData] = useState({  
        email:"",
        password:"",
        isRemember:false
    })
    useEffect(() => {
        dispatch(clearMessage())
    },[dispatch])
    const handleSubmit = (e)=>{
        e.preventDefault();  
        dispatch(login(userData))
    }
    useEffect(() => {
        if(isLoggedIn && user){  
            history.push("/dashboard")
        }
    },[isLoggedIn])
    
    return (
        <Page>
            <DefaultNavbar />
            <Container>
                <Card>
                    <CardHeader color="lightGreen">
                        <H5 color="white" style={{ marginBottom: 0 }}>
                            Login
                        </H5>
                    </CardHeader>
                        <form onSubmit={(e) =>{handleSubmit(e)}}>
                    <CardBody>
                        <div className="mb-12 px-4 bg-bb">
                            <InputIcon
                                type="email"
                                color="lightBlue"
                                placeholder="Email Address"
                                iconName="email"
                                required
                                onChange={(e)=>{setUserData({...userData,email:e.target.value})}}
                            />
                        </div>
                        <div className="mb-8 px-4">
                            <InputIcon
                                type="password"
                                color="lightBlue"
                                placeholder="Password"
                                iconName="lock"
                                required
                                onChange={(e)=>{setUserData({...userData,password:e.target.value})}}
                            />
                        </div>
                        <div className="mb-4 px-4">
                            <Checkbox
                                color="lightBlue"
                                text="Remember Me"
                                id="remember"
                                onChange={(e) => {setUserData({...userData,isRemember:e.target.checked})}}
                            />
                        </div>
                        <div className="text-sm text-center text-orange-600">{message}</div>
                    </CardBody>
                 
                    <CardFooter>
                        
                        <div className="flex justify-center bg-bb">
                            <Button
                                color="lightBlue"
                                buttonType="link"
                                size="lg"
                                ripple="dark"
                                type="submit"
                            >
                                Get Started
                            </Button>
                        </div>
                    </CardFooter>
                    </form>
                </Card>
            </Container>
            {/* <SimpleFooter /> */}
        </Page>
    );
}
