import { configureStore } from '@reduxjs/toolkit'
import authReducer from "./slices/auth";
import messageReducer from "./slices/message";
import loaderReducer from "./slices/loader";
import drawerReducer from "./slices/drawer";
const reducer = {
  auth: authReducer,
  message: messageReducer,
  loading:loaderReducer,
  drawer: drawerReducer
}
const store = configureStore({
  reducer: reducer,
  devTools: true,
})
export default store;