import { createSlice } from "@reduxjs/toolkit";
const initialState = false;
const loaderSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      return true
    },
    clearLoading: () => {
      return false;
    },
  },
});
const { reducer, actions } = loaderSlice;
export const { setLoading, clearLoading } = actions
export default reducer;