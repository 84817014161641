import Card from '@material-tailwind/react/Card';
import CardHeader from '@material-tailwind/react/CardHeader';
import CardBody from '@material-tailwind/react/CardBody';
import Image from '@material-tailwind/react/Image';
import Progress from '@material-tailwind/react/Progress';
import Pagination from './Pagination'
export default function CardTable({tableName="Card Table",columns=["Hello"], data=[],setPageNumber,pageNumber,count}) {
    return (
        <Card>
            <CardHeader color="purple" contentPosition="left">
                <h2 className="text-white text-2xl">{tableName}</h2>
            </CardHeader>
            <CardBody>
                <div className="overflow-x-auto">
                    <table className="items-center w-full bg-transparent border-collapse">
                        <thead>
                            <tr>
                                {columns.map((c,i)=>
                                <th key={i} className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                    {c}
                                </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((d, i)=>
                            <tr key={i} className="hover:bg-green-200 cursor-pointer">
                                <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                    {d.order_id}
                                </td>
                                <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                    {d.products.length}
                                </td>
                                <td className="uppercase border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                    {d.status_steps.slice(-1)[0].status}
                                </td>
                                <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left ">
                                    <i className="fa fa-eye "></i>
                                </td>
                            </tr>
                            )}
                        </tbody>
                    </table>
                    <div className="p-10">
                    <Pagination setPageNumber={setPageNumber} pageNumber={pageNumber} count={count}></Pagination>
                    </div>
                   
                </div>
            </CardBody>
        </Card>
    );
}
