import { Switch, Route, Redirect } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import { useDispatch, useSelector } from "react-redux";
function AdminLayout({ component: Component, path }) {
  const { isLoggedIn } = useSelector((state) => state.auth);
  return (
    <Route
      exact={true}
      path={path}
      render={() => {
        return isLoggedIn ? (
          <>
            <Sidebar />
            <div className="md:ml-64">
              <Component></Component>
              <Footer />
            </div>
          </>
        ) : (
          <Redirect to="/login"></Redirect>
        );
      }}
    ></Route>
  );
}

export default AdminLayout;
