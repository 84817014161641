import axios from "axios";
import AuthHeader from "./auth-header";
AuthHeader()
const getUsers = async (data) => {
  
    try{  
        let users = await axios.post("/api/user/fetch-users",data);
        return users.data
    }
    catch(e){  

    }
  };
  const userService = {
    getUsers
  };
  export default userService;