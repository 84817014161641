import {useState, useEffect} from "react"
import StatusCard from 'components/StatusCard';
import SettingsForm from 'components/SettingsForm';
import ProfileCard from 'components/ProfileCard';
import TableCard from 'components/CouponsTable';
import Couponservice from "../services/coupon.service";
import { clearLoading,setLoading } from "../slices/loader";
import { clearDrawer,setDrawer } from "../slices/drawer";
import { useDispatch, useSelector } from "react-redux";
const columns = ["Name", "Code","Start","End","Claims","Action"]
export default function Dashboard() {
    const {open, side, data,page} = useSelector((state) => state.drawer);
    const [type,setType] = useState("running")
    const [Coupons, setCoupons] = useState([])
    const [pageNumber, setPageNumber] = useState(0)
    const [count, setCount] = useState(0)
    const dispatch = useDispatch();
    async function getCoupons (){  
        dispatch(setLoading())
        let response = await Couponservice.getCoupons({type,skip:pageNumber*10})
        setCoupons(response.coupons)
        setCount(response.count)
        dispatch(clearLoading())
    }
    function OpenDetails(i){  
        dispatch(setDrawer({data:Coupons[i],side:"right", page:"Coupons"}))
    }
    useEffect(() => {
        if(!open){  
            getCoupons()
        }
    },[pageNumber,type, open])
    return (
        <>
            <div className="bg-light-blue-500 pt-14 pb-28 px-3 md:px-8 h-auto">
                <div className="container mx-auto max-w-full">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 px-4 mb-16">
                        <TableCard 
                        data={Coupons}
                        columns={columns}
                        setPageNumber={setPageNumber}
                        pageNumber={pageNumber}
                        type={type} 
                        count={count}
                        setType={setType}
                        OpenDetails={OpenDetails}
                        dispatch={dispatch}
                        setDrawer={setDrawer}
                        tableName="Coupons"/>
                        
                    </div>
                </div>
                </div>
            </div>
        </>
    );
}
