import React, { useState, useEffect  } from "react";
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import {  useSelector } from "react-redux";
import { clearMessage } from "../slices/message";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

export default function SimpleBackdrop() {
    const loading = useSelector((state) => state.loading);
    const targetElement = document.querySelector("body")
    useEffect(()=>{
        if(loading){  
            disableBodyScroll(targetElement)
        }else{  
            clearAllBodyScrollLocks()
        }
    },[loading])
  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 999 }}
        open={loading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}