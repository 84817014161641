import {useState, useEffect} from "react"
import StatusCard from 'components/StatusCard';
import SettingsForm from 'components/SettingsForm';
import ProfileCard from 'components/ProfileCard';
import TableCard from 'components/SellerTable';
import SellerService from "../services/seller.service";
import { clearLoading,setLoading } from "../slices/loader";
import { clearDrawer,setDrawer } from "../slices/drawer";
import { useDispatch, useSelector } from "react-redux";
const columns = ["Name", "Email","Phone","Business Name","City","Action"]
export default function Dashboard() {
    const {open, side, data,page} = useSelector((state) => state.drawer);
    const [type,setType] = useState(0)
    const [sellers, setSellers] = useState([])
    const [pageNumber, setPageNumber] = useState(0)
    const [count, setCount] = useState(0)
    const dispatch = useDispatch();
    async function getSellers (){  
        dispatch(setLoading())
        let response = await SellerService.getSellers({type,skip:pageNumber*10})
        setSellers(response.sellers)
        setCount(response.count)
        dispatch(clearLoading())
    }
    function OpenDetails(i){  
        dispatch(setDrawer({data:sellers[i],side:"right", page:"sellers"}))
    }
    useEffect(() => {
        if(!open){  
            getSellers()
        }
    },[pageNumber,type, open])
    return (
        <>
            <div className="bg-light-blue-500 pt-14 pb-28 px-3 md:px-8 h-auto">
                <div className="container mx-auto max-w-full">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 px-4 mb-16">
                        <TableCard 
                        data={sellers}
                        columns={columns}
                        setPageNumber={setPageNumber}
                        pageNumber={pageNumber}
                        type={type} 
                        count={count}
                        setType={setType}
                        OpenDetails={OpenDetails}
                        tableName="Sellers"/>
                        
                    </div>
                </div>
                </div>
            </div>
        </>
    );
}
