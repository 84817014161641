import { Switch, Route, Redirect } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import Dashboard from "pages/Dashboard";
import Settings from "pages/Settings";
import Commissions from "pages/Commissions";
import Coupons from "pages/Coupons";
import Sellers from "pages/Sellers";
import Orders from "pages/Orders";
import Maps from "pages/Maps";

import Profile from "pages/Profile";
import Login from "pages/Login";
import AdminLayout from "pages/AdminLayout";
import Loader from "components/Loader";
import Drawer from "components/Drawer";
// Tailwind CSS Style Sheet
import "assets/styles/tailwind.css";

function App() {
  return (
    <>
     <Loader></Loader>
     <Drawer></Drawer>
      <BrowserRouter>
        <Switch>
          <AdminLayout exact path="/dashboard" component={Dashboard} />
          <AdminLayout exact path="/commissions" component={Commissions} />
          <AdminLayout exact path="/coupons" component={Coupons} />
          <AdminLayout exact path="/sellers" component={Sellers} />
          <AdminLayout exact path="/settings" component={Settings} />
          <AdminLayout exact path="/orders" component={Orders} />
          <AdminLayout exact path="/maps" component={Maps} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/login" component={Login} />
          <Redirect from="*" to="/login" />
        </Switch>
      </BrowserRouter>
     
    </>
  );
}

export default App;
