import { useState } from 'react';
import { Link,useLocation, useHistory } from 'react-router-dom';
import Navbar from '@material-tailwind/react/Navbar';
import NavbarContainer from '@material-tailwind/react/NavbarContainer';
import NavbarWrapper from '@material-tailwind/react/NavbarWrapper';
import NavbarBrand from '@material-tailwind/react/NavbarBrand';
import NavbarToggler from '@material-tailwind/react/NavbarToggler';
import NavbarCollapse from '@material-tailwind/react/NavbarCollapse';
import Nav from '@material-tailwind/react/Nav';
import NavLink from '@material-tailwind/react/NavLink';
import Dropdown from '@material-tailwind/react/Dropdown';
import DropdownItem from '@material-tailwind/react/DropdownItem';
import Icon from '@material-tailwind/react/Icon';
import Button from '@material-tailwind/react/Button';
import Logo from 'assets/img/Logo.png';
export default function DefaultNavbar() {
    const [openNavbar, setOpenNavbar] = useState(false);
    const location = useLocation().pathname;
    return (
        <Navbar className="z-999" color="green" navbar>
            <NavbarContainer >
                <NavbarWrapper>
                    <a
                        href="/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <NavbarBrand><img className="h-12" src={Logo}></img></NavbarBrand>
                    </a>
                    <NavbarToggler
                        onClick={() => setOpenNavbar(!openNavbar)}
                        color="white"
                    />
                </NavbarWrapper>

                <NavbarCollapse open={openNavbar}>
                    <Nav >
                        <div className="flex flex-col  lg:flex-row lg:items-center">
                            <NavLink
                                href="https://material-tailwind.com/documentation/quick-start?ref=mtk"
                                target="_blank"
                                rel="noreferrer"
                                ripple="light"
                            >
                                <Icon family="font-awesome" name="fa-solid fa-gem" size="2xl" />
                                &nbsp;Shop
                            </NavLink>
                            <NavLink
                                href="https://material-tailwind.com/components?ref=mtk"
                                target="_blank"
                                rel="noreferrer"
                                ripple="light"
                            >
                                <Icon name="apps" size="2xl" />
                                &nbsp;Categories
                            </NavLink>
                            <NavLink
                                href="#"
                                target="_blank"
                                rel="noreferrer"
                                ripple="light"
                            >
                                <Icon
                                    family="font-awesome"
                                    name="fa fa-ring"
                                    size="xl"
                                />
                                &nbsp;Customize Ring
                            </NavLink>
                            <NavLink
                                href="#"
                                target="_blank"
                                rel="noreferrer"
                                ripple="light"
                            >
                                <Icon
                                    family="font-awesome"
                                    name="fas fa-watch"
                                    size="xl"
                                />
                                &nbsp;Diamond Watches
                            </NavLink>
                            <NavLink
                                href="#"
                                target="_blank"
                                rel="noreferrer"
                                ripple="light"
                            >
                                About Us
                            </NavLink>
                        </div>
                    </Nav>
                </NavbarCollapse>
            </NavbarContainer>
        </Navbar>
    );
}
