
import sellerService from '../services/seller.service'
export default function SellerDetails({data, clearLoading, setLoading, dispatch, clearDrawer}){  
   async function ApproveDetails(action){  
        dispatch(setLoading())
        try {
           let response = await sellerService.approveSeller({id:data._id, action:action})
            dispatch(clearLoading())
            dispatch(clearDrawer())
        } catch (error) {
            dispatch(clearLoading())
        }
    }
    return(  
        <div className="container p-10">
            <div className="text-purple-500 text-md mt-3 mb-6 font-light uppercase">Personal Details</div>
            <div>
                    <ul>
                        <li>Name : {data.name}</li>
                        <li>Email : {data.email}</li>
                        <li>Phone : {data.phoneNumber}</li>
                        <li>ID : <a className="text-blue-500" download href={data.ID}>Download</a></li>
                    </ul>
            </div>
            <div className="text-purple-500 text-md mt-3 mb-6 font-light uppercase">Business Details</div>
            <div>
                    <ul>
                        <li>Business Name : {data.shop[0].shopName}</li>
                        <li>City : {data.shop[0].city}</li>
                        <li>GST : {data.shop[0].gstNumber}</li>
                        <li>PIN : {data.shop[0].address}</li>
                        <li>Country : {data.shop[0].country}</li>
                        <li>Address : </li>
                        <li>{data.shop[0].address}</li>
                    </ul>
            </div>
            <div className="mt-10 flex justify-evenly">
                   {!data.is_approved &&<button onClick={()=>{ApproveDetails(true)}} type="button" class="py-3 px-4 text-sm font-medium text-center text-white bg-green-700 rounded-lg  hover:bg-green-500 focus:outline-none hover:ring-blue-300">Approve</button>}
                   {!data.is_rejected &&<button type="button" onClick={()=>{ApproveDetails(false)}} class=" py-3 px-5 text-sm font-medium text-center text-white bg-red-700 rounded-lg  hover:bg-red-500 focus:outline-none hover:ring-blue-300">Reject</button>}
            </div>
        </div>
    )
}