import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
export default function Pagination({setPageNumber,pageNumber,count}){  
    const handlePageClick = ({selected})=>{  
        setPageNumber(selected)
    }
    return <ReactPaginate
    breakLabel="..."
    nextLabel=" >"
    onPageChange={handlePageClick}
    pageRangeDisplayed={3}
    activeClassName=""
    className="inline-flex -space-x-px"
    pageCount={Math.ceil(count/10)}
    pageClassName=""
    forcePage={pageNumber}
    pageLinkClassName="py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
    activeLinkClassName="py-2 px-3 text-blue-600 bg-blue-50 border border-gray-300 hover:bg-blue-100 hover:text-blue-700"
    previousLinkClassName="py-2 px-3 ml-0 leading-tight text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
    nextLinkClassName="py-2 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
    breakLinkClassName="py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
    previousLabel="< "
    renderOnZeroPageCount={null}
    >

    </ReactPaginate>
}