import axios from "axios";
import AuthHeader from "./auth-header";
AuthHeader()
const getSellers = async (data) => {
    try{  
        let sellers = await axios.post("/api/b2b/sellers",data);
        return sellers.data
    }
    catch(e){  

    }
  };
const approveSeller = async (data)=>{  
   return axios.post("/api/b2b/approve-seller",data);
}
  const sellerService = {
    getSellers,
    approveSeller
  };
  export default sellerService;