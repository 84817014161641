import React, {useEffect, useState} from 'react';
import StatusCard from 'components/StatusCard';
import TableCard from 'components/OrderTableCard';
import OrderService from "../services/order.service";
import { clearLoading,setLoading } from "../slices/loader";
import { useDispatch } from "react-redux";
const columns = ["Order ID", "Product Count","Status","Action"]
export default function Orders() {
    const [orders, setOrders] = useState([])
    const [pageNumber, setPageNumber] = useState(0)
    const [count, setCount] = useState(0)
    const dispatch = useDispatch();
    useEffect(()=>{
        fetchOrders()
    },[pageNumber])
    const fetchOrders = async ()=>{  
        dispatch(setLoading())
        const response = await OrderService.getOrders(pageNumber*10)
        setOrders(response.Order)
        setCount(response.count)
        dispatch(clearLoading())
    }
    return (
        <>
            <div className="bg-light-blue-500 pt-8 pb-28 px-3 md:px-8 h-auto">
            <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 px-4 mb-16">
                        <TableCard 
                        tableName="Your Orders"
                        data={orders}
                        columns={columns}
                        setPageNumber={setPageNumber}
                        pageNumber={pageNumber}
                        count={count}
                        />
                    </div>
                </div>
                {/* <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4">
                        <StatusCard
                            color="pink"
                            icon="trending_up"
                            title="Traffic"
                            amount="350,897"
                            percentage="3.48"
                            percentageIcon="arrow_upward"
                            percentageColor="green"
                            date="Since last month"
                        />
                        <StatusCard
                            color="orange"
                            icon="groups"
                            title="New Users"
                            amount="2,356"
                            percentage="3.48"
                            percentageIcon="arrow_downward"
                            percentageColor="red"
                            date="Since last week"
                        />
                        <StatusCard
                            color="purple"
                            icon="paid"
                            title="Sales"
                            amount="924"
                            percentage="1.10"
                            percentageIcon="arrow_downward"
                            percentageColor="orange"
                            date="Since yesterday"
                        />
                        <StatusCard
                            color="blue"
                            icon="poll"
                            title="Performance"
                            amount="49,65%"
                            percentage="12"
                            percentageIcon="arrow_upward"
                            percentageColor="green"
                            date="Since last month"
                        />
                    </div>
                </div> */}
            </div>

         
        </>
    );
}
