import axios from "axios";
import AuthHeader from "./auth-header";
AuthHeader()
const getOrders = async (skip) => {
    try{  
        let orders = await axios.post("/api/order/order-by-user?skip="+skip);
        return orders.data
    }
    catch(e){  

    }
  };
  const orderService = {
    getOrders
  };
  export default orderService;