import axios from "axios";
import AuthHeader from "./auth-header";
AuthHeader()
const getCategories = async (data) => {
    try{  
        let categories = await axios.post("/api/category/fetch-all-categories",data);
        return categories.data
    }
    catch(e){  

    }
  };
  const categoryService = {
    getCategories
  };
  export default categoryService;