import {useState, useEffect} from "react"
import Modal from '@mui/material/Modal';
export default function BasicModal({children:Component, open, setOpen,onClose,...restProps}) {
  const handleOpen = () => setOpen(true);
  const handleClose = () => onClose();
  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
      >
        <Component {...restProps} handleClose={handleClose}></Component>
      </Modal>
    </div>
  );
}