import { createSlice } from "@reduxjs/toolkit";
const initialState = {open:false,side:"right",data:null, page:null};
const drawerSlice = createSlice({
  name: "drawer",
  initialState,
  reducers: {
    setDrawer: (state, action) => {
      return {open:true,data:action.payload.data,side:action.payload.side, page:action.payload.page}
    },
    clearDrawer: () => {
      return {open:false,data:null,side:"right",page:null};
    },
  },
});
const { reducer, actions } = drawerSlice;
export const { setDrawer, clearDrawer } = actions
export default reducer;