import Card from '@material-tailwind/react/Card';
import CardHeader from '@material-tailwind/react/CardHeader';
import CardBody from '@material-tailwind/react/CardBody';
import Button from '@material-tailwind/react/Button';
import Input from '@material-tailwind/react/Input';
import Textarea from '@material-tailwind/react/Textarea';

export default function SettingsForm({formName="My Account",Subname="Settings"}) {
    return (
        <Card>
            <CardHeader color="purple" contentPosition="none">
                <div className="w-full flex items-center justify-between">
                    <h2 className="text-white text-2xl">{formName}</h2>
                    <Button
                        color="transparent"
                        buttonType="link"
                        size="lg"
                        style={{ padding: 0 }}
                    >
                        {Subname}
                    </Button>
                </div>
            </CardHeader>
            <CardBody>
                <form>
                    <h6 className="text-purple-500 text-sm mt-3 mb-6 font-light uppercase">
                        User Information
                    </h6>
                    <div className="flex flex-wrap mt-10">
                        <div className="w-full lg:w-6/12 md:pr-4 mb-10 font-light">
                            <Input
                                type="text"
                                color="purple"
                                placeholder="Name"
                            />
                        </div>
                        <div className="w-full lg:w-6/12 md:pl-4 mb-10 font-light">
                            <Input
                                type="email"
                                color="purple"
                                placeholder="Email Address"
                            />
                        </div>
                        <div className="w-full lg:w-6/12 md:pr-4 mb-10 font-light">
                            <Input
                                type="text"
                                color="purple"
                                placeholder="Phone"
                            />
                        </div>
                        <div className="w-full lg:w-6/12 md:pl-4 mb-10 font-light">
                            <Input
                                type="password"
                                color="purple"
                                placeholder="Password"
                            />
                        </div>
                    </div>

                    <h6 className="text-purple-500 text-sm my-6 font-light uppercase">
                        Contact Information
                    </h6>
                    <div className="flex flex-wrap mt-10">
                        <div className="w-full lg:w-12/12 mb-10 font-light">
                            <Input
                                type="text"
                                color="purple"
                                placeholder="Address"
                            />
                        </div>
                        <div className="w-full lg:w-4/12 md:pr-4 mb-10 font-light">
                            <Input
                                type="text"
                                color="purple"
                                placeholder="City"
                            />
                        </div>
                        <div className="w-full lg:w-4/12 md:px-4 mb-10 font-light">
                            <Input
                                type="text"
                                color="purple"
                                placeholder="Country"
                            />
                        </div>
                        <div className="w-full lg:w-4/12 md:pl-4 mb-10 font-light">
                            <Input
                                type="text"
                                color="purple"
                                placeholder="Postal Code"
                            />
                        </div>
                    </div>
                    <h6 className="text-purple-500 text-sm my-6 font-light uppercase">
                        Shop Information
                    </h6>
                    <div className="flex flex-wrap mt-10">
                        <div className="w-full lg:w-6/12 md:pr-4 mb-10 font-light">
                            <Input
                                type="text"
                                color="purple"
                                placeholder="Shop Name"
                            />
                        </div>
                        <div className="w-full lg:w-6/12 md:pr-4 mb-10 font-light">
                            <Input
                                type="text"
                                color="purple"
                                placeholder="GST Number"
                            />
                        </div>
                        <div className="w-full lg:w-12/12 mb-10 font-light">
                            <Input
                                type="text"
                                color="purple"
                                placeholder="Address"
                            />
                        </div>
                        <div className="w-full lg:w-4/12 md:pr-4 mb-10 font-light">
                            <Input
                                type="text"
                                color="purple"
                                placeholder="City"
                            />
                        </div>
                        <div className="w-full lg:w-4/12 md:px-4 mb-10 font-light">
                            <Input
                                type="text"
                                color="purple"
                                placeholder="Country"
                            />
                        </div>
                        <div className="w-full lg:w-4/12 md:pl-4 mb-10 font-light">
                            <Input
                                type="text"
                                color="purple"
                                placeholder="Postal Code"
                            />
                        </div>
                        <div className="w-full lg:w-6/12 mb-10 font-light">
                            <Input
                                type="file"
                                color="purple"
                                placeholder="Upload your ID"
                            />
                        </div>
                        <div className="w-full lg:w-6/12 mb-10 font-light">
                            <Button
                                color="lightBlue"
                                size="lg"
                                ripple="dark"
                                type="submit"
                                className="float-right"
                            >Register</Button>
                        </div>
                    </div>
                    {/* <h6 className="text-purple-500 text-sm my-6 font-light uppercase">
                        Description
                    </h6>
                    <div className="flex flex-wrap mt-10 font-light">
                        <Textarea color="purple" placeholder="Description" />
                    </div> */}
                   
                </form>
            </CardBody>
        </Card>
    );
}
