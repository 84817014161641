import axios from "axios";
const login = (email, password, isRemember) => {
  return axios
    .post("/api/admin/signin", {
      email,
      password,
    })
    .then((response) => {
      if (response.data.token && isRemember) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }else if(response.data.token && !isRemember){  
        sessionStorage.setItem("user", JSON.stringify(response.data))
      }
      return response.data;
    });
};
const logout = () => {
  localStorage.removeItem("user");
  sessionStorage.removeItem("user");
};
const authService = {
  login,
  logout,
};
export default authService;