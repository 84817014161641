import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import Image from "@material-tailwind/react/Image";
import Progress from "@material-tailwind/react/Progress";
import Pagination from "./Pagination";
export default function CardTable({
  tableName = "Card Table",
  columns = ["Hello"],
  data = [],
  setPageNumber,
  pageNumber,
  count,
  type,
  setType,
  OpenDetails
}) {

  return (
    <Card>
      <CardHeader color="purple" className="grid-cols-2"  contentPosition="left">
        <h2 className="text-white text-2xl">{tableName}</h2>
        <div class="inline-flex rounded-md shadow-sm flex justify-end" role="group">
          <button
            type="button"
            class={`py-2 px-4 text-sm font-medium text-gray-900 bg-white rounded-l-lg border border-gray-200 focus:outline-0 ${type==0 && "z-10  ring-2 bg-blue-500 text-white"}`}
          onClick={()=>{setType(0);setPageNumber(0);}}
          >
            Approved
          </button>
          <button
            type="button"
            class={`py-2 px-4 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200  focus:outline-0 ${type==1 && "z-10  ring-2 bg-blue-500 text-white"}`}
            onClick={()=>{setType(1);setPageNumber(0);}}
          >
            Pending
          </button>
          <button
            type="button"
            class={`py-2 px-4 text-sm font-medium text-gray-900 bg-white rounded-r-md border border-gray-200 focus:outline-0 ${type==2 && "z-10  ring-2 bg-blue-500 text-white"}`}
            onClick={()=>{setType(2);setPageNumber(0);}}
          >
            Rejected
          </button>
        </div>
      </CardHeader>
      <CardBody>
        <div className="overflow-x-auto">
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                {columns.map((c, i) => (
                  <th
                    key={i}
                    className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left"
                  >
                    {c}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((d, i) => (
                <tr key={i} className="hover:bg-green-100 cursor-pointer">
                  <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                    {d.name}
                  </td>
                  <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                    {d.email}
                  </td>
                  <td className="uppercase border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                    {d.phoneNumber}
                  </td>
                  <td className="uppercase border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                    {d.shop[0]?.shopName}
                  </td>
                  <td className="uppercase border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                    {d.shop[0]?.city}
                  </td>
                  <td className="border-b border-gray-200 align-middle font-light text-sm px-2 py-4 text-left ">
                  <button onClick={()=>{OpenDetails(i)}} type="button" class="py-2 px-3 text-xs font-medium text-center text-white bg-blue-700 rounded-lg  focus:bg-blue-500 focus:outline-none focus:ring-blue-300">Details</button>
                 
                    {/* {type!=0 &&<button type="button" class="py-2 px-3 text-xs font-medium text-center text-white bg-green-700 rounded-lg  focus:bg-green-500 focus:outline-none focus:ring-blue-300">Approve</button>}

                   
                   <button type="button" class="py-2 px-3 text-xs font-medium text-center text-white bg-red-700 rounded-lg  focus:bg-red-500 focus:outline-none focus:ring-blue-300">Reject</button> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="p-10">
            <Pagination
              setPageNumber={setPageNumber}
              pageNumber={pageNumber}
              count={count}
            ></Pagination>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}
