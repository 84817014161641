import { useState, useEffect } from "react";
import Modal from "./Modal";
import couponService from "../services/coupon.service";
import categoryService from "../services/category.service";
import userService from "../services/user.service";
import Input from "@material-tailwind/react/Input";
import Table from "./Table";
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
export default function SellerDetails({
  data,
  clearLoading,
  setLoading,
  dispatch,
  clearDrawer,
}) {
  const [couponDetails, setCouponDetails] = useState({

    code: "",
    name: "",
    description: "",
    tnc: "",
    allDiscountType: "percentage",
    allDiscountValue: 0,
    category: {
      selectedData:[],
      isAllSelected:false
    },
    diamondDiscountType: "percentage", diamondDiscountValue: 0,
    endDate: (new Date()).toISOString().slice(0, 16),
    goldDiscountType: "percentage",
    goldDiscountValue: 0,
    labourDiscountType: "percentage",
    labourDiscountValue: 0,
    perClientMaxAmount: 0,
    perClientVouchers: 0,
    startDate: (new Date()).toISOString().slice(0, 16),
    user: {
      selectedData:[],
      isAllSelected:false
    },
    vouchers: 0
  })
  const inputHandler = (e) => {
    if (e.target.name == "code") {
      e.target.value = e.target.value.toUpperCase()
    }
    setCouponDetails(prev => { return { ...prev, [e.target.name]: e.target.value } })
  }
  const [tableData, setTableData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [rowKeys, setRowKeys] = useState([]);
  const [headCells, setHeadCells] = useState([]);
  const [fetchData, setFetchData] = useState({
    search: "",
    skip: 0,
  });
  const [selectedData, setSelectedData] = useState([]);
  const [isAllSelected, setAllSelected] = useState(false);
  const [users, setusers] = useState({
    isAllSelected: false,
    selectedData: [],
  });
  const [categories, setcategories] = useState({
    isAllSelected: false,
    selectedData: [],
  });
  const [type, setType] = useState("");
  useEffect(() => {
    if (type) {
      RenderTable();
    }
  }, [type, fetchData]);

  function RenderTable() {
    if (type === "users") {
      setRowKeys(["name", "email"]);
      setHeadCells([
        {
          id: "name",
          numeric: false,
          disablePadding: true,
          label: "Name",
        },
        {
          id: "email",
          numeric: false,
          disablePadding: true,
          label: "Email",
        },
      ]);
      return fetchUsers();
    } else if (type == "categories") {
      setRowKeys(["cName", "actions"]);
      setHeadCells([
        {
          id: "cName",
          numeric: false,
          disablePadding: true,
          label: "Category Name",
        },
        {
          id: "actions",
          numeric: false,
          disablePadding: true,
          label: "Sub Categories",
        },
      ]);
      return fetchCategories();
    }
  }
  async function fetchUsers() {
    dispatch(setLoading());
    let response = await userService.getUsers(fetchData);
    if (response) {
      setTableData(response.data);
      setDataCount(response.count);
      dispatch(clearLoading());
      setOpen(true);
    }
  }
  async function fetchCategories() {
    dispatch(setLoading());
    let response = await categoryService.getCategories(fetchData);
    if (response) {
      setTableData(response.data.map(a => {
        return {
          _id: a._id,
          cName: a.cName,
          actions: <>
            <IconButton onClick={(e) => { alert(a.cName) }} key={a._id} className="focus:outline-none">
              <AddIcon></AddIcon>
            </IconButton>
          </>
        }
      }));
      setDataCount(response.count);
      dispatch(clearLoading());
      setOpen(true);
    }
  }
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setFetchData({
      search: "",
      skip: 0,
    });
  }, [open]);
  const onSave = () => {
    if (isAllSelected || selectedData.length) {
      if (type === "users") {
        setusers({
          isAllSelected: isAllSelected,
          selectedData: selectedData,
        });
        setCouponDetails(prev => { return { ...prev, user: { isAllSelected, selectedData } } })
      } else if (type == "categories") {
        setcategories({
          isAllSelected: isAllSelected,
          selectedData: selectedData,
        });
        setCouponDetails(prev => { return { ...prev, category: { isAllSelected, selectedData } } })
      }
      setType("");
      setFetchData({
        search: "",
        skip: 0,
      });
      setOpen(false);
      setAllSelected(false);
      setSelectedData([]);
    }
  };
  const onClose = () => {
    setType("");
    setFetchData({
      search: "",
      skip: 0,
    });
    setOpen(false);
    setAllSelected(false);
    setSelectedData([]);
  }
  const selectTarget = (t) => {
    dispatch(setLoading());
    setType(t);
    if (t == "users") {
      setAllSelected(users.isAllSelected);
      setSelectedData(users.selectedData);
    } else if (t == "categories") {
      setAllSelected(categories.isAllSelected);
      setSelectedData(categories.selectedData);
    } else {
      dispatch(clearLoading());
    }
  };
  async function AddCoupon() {
    dispatch(setLoading())
    try {
      let response = await couponService.addCoupon(couponDetails)
      if(response.data.success){
        dispatch(clearLoading())
        dispatch(clearDrawer())
      }
      
    } catch (error) {
      dispatch(clearLoading())
    }
  }
  return (
    <div className="container p-10">
      <Modal
        children={Table}
        setOpen={setOpen}
        open={open}
        headCells={headCells}
        rowKeys={rowKeys}
        rows={tableData}
        fetchData={fetchData}
        setFetchData={setFetchData}
        count={dataCount}
        setSelectedData={setSelectedData}
        selectedData={selectedData}
        isAllSelected={isAllSelected}
        setAllSelected={setAllSelected}
        onSave={onSave}
        onClose={onClose}
      ></Modal>
      <div className="text-purple-500 text-md mt-3 mb-6 font-light uppercase">
        Create Coupon
      </div>
      <div>
        <div className="flex flex-wrap mt-10">
          <div className="w-full lg:w-6/12 md:pr-4 mb-10 font-light">
            <Input
              onChange={inputHandler}
              name="name"
              value={couponDetails.name}
              type="text" color="purple" placeholder="Coupon Name" />
          </div>
          <div className="w-full lg:w-6/12 md:pl-4 mb-10 font-light">
            <Input
            value={couponDetails.code}
              type="text"
              color="purple"
              maxLength={10}
              placeholder="Coupon Code"
              onChange={inputHandler}
              name="code"
            />
          </div>

          <div className="w-full lg:w-6/12 md:pr-4 mb-10 font-light">
            <Input
            value={couponDetails.description}
            name="description" onChange={inputHandler} type="text" color="purple" placeholder="Description" />
          </div>
          <div className="w-full lg:w-6/12 md:pl-4 mb-10 font-light">
            <Input
             value={couponDetails.tnc}
              type="text"
              color="purple"
              placeholder="Terms and Conditions"
              name="tnc"
              onChange={inputHandler}
            />
          </div>
          <div className="w-full lg:w-6/12 md:pr-4 mb-10 font-light">
            <Input
            value={couponDetails.vouchers}
              type="number"
              color="purple"
              placeholder="Total No. of Vouchers"
              name="vouchers"
              onChange={inputHandler}
            />
          </div>

        </div>
      </div>
      <div className="text-purple-500 text-base mt-3 mb-6 font-light uppercase">
        Date
      </div>
      <div className="w-full lg:w-6/12 mb-10 font-light flex">
        <div className="">
          <Input
          label="Start date"
          value={couponDetails.startDate}
          onChange={inputHandler} name="startDate" type="datetime-local"></Input>
        </div>
        <div className="ml-5">
          <Input
          label="End date"
          value={couponDetails.endDate}
          onChange={inputHandler} name="endDate" type="datetime-local"></Input>
        </div>

      </div>
      <div className="text-purple-500 text-base mt-3 mb-6 font-light uppercase">
        Amount
      </div>
      <div className="grid grid-cols-3">
        <div>
          <div>Material</div>
          <div className="mt-1">Gold</div>
          <div className="mt-4">Diamond</div>
          <div className="mt-3">Labour</div>
          <div className="mt-4">All</div>
        </div>
        <div>
          Discount Type
          <div>
            <div className="w-full lg:w-6/12 font-light flex">
              <div>
                <select
                value={couponDetails.goldDiscountType}
                onChange={inputHandler} name="goldDiscountType" className="bg-gray-50 border border-gray-300 text-gray-800 text-sm p-1 mr-1 focus:outline-none">
                  <option value="percentage">%</option>
                  <option value="amount">INR</option>
                </select>
              </div>
              <Input
              value={couponDetails.goldDiscountValue}
                onChange={inputHandler}
                name="goldDiscountValue"
                type="number"
                min={1}
                max={100}
                color="purple"
                placeholder="Amount"
              />
            </div>
          </div>
          <div>
            <div className="w-full lg:w-6/12 font-light flex">
              <div>
                <select
                value={couponDetails.diamondDiscountType}
                onChange={inputHandler} name="diamondDiscountType" className="bg-gray-50 border border-gray-300 text-gray-800 text-sm p-1 mr-1 focus:outline-none">
                  <option value="percentage">%</option>
                  <option value="amount">INR</option>
                </select>
              </div>
              <Input
              value={couponDetails.diamondDiscountValue}
                onChange={inputHandler}
                name="diamondDiscountValue"
                type="number"
                min={1}
                max={100}
                color="purple"
                placeholder="Amount"
              />
            </div>
          </div>
          <div>
            <div className="w-full lg:w-6/12 font-light flex">
              <div>
                <select
                value={couponDetails.labourDiscountType}
                onChange={inputHandler} name="labourDiscountType" className="bg-gray-50 border border-gray-300 text-gray-800 text-sm p-1 mr-1 focus:outline-none">
                  <option value="percentage">%</option>
                  <option value="amount">INR</option>
                </select>
              </div>
              <Input
              value={couponDetails.labourDiscountValue}
                onChange={inputHandler}
                name="labourDiscountValue"
                type="number"
                min={1}
                max={100}
                color="purple"
                placeholder="Amount"
              />
            </div>
          </div>
          <div>
            <div className="w-full lg:w-6/12 font-light flex">
              <div>
                <select
                value={couponDetails.allDiscountType}
                onChange={inputHandler} name="allDiscountType" className="bg-gray-50 border border-gray-300 text-gray-800 text-sm p-1 mr-1 focus:outline-none">
                  <option value="percentage">%</option>
                  <option value="amount">INR</option>
                </select>
              </div>
              <Input
              value={couponDetails.allDiscountValue}
                onChange={inputHandler}
                name="allDiscountValue"
                type="number"
                min={1}
                max={100}
                color="purple"
                placeholder="Amount"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="text-purple-500 text-base mt-3 mb-6 font-light uppercase">
        Per Client
      </div>
      <div>
        <div className="flex flex-wrap mt-10">
          <div className="w-full lg:w-6/12 md:pr-4 mb-10 font-light">
            <Input 
            value={couponDetails.perClientVouchers}
            onChange={inputHandler} name="perClientVouchers" type="number" color="purple" placeholder="Vouchers" />
          </div>
          <div className="w-full lg:w-6/12 md:pl-4 mb-10 font-light">
            <Input
            value={couponDetails.perClientMaxAmount}
            onChange={inputHandler} name="perClientMaxAmount" type="number" color="purple" placeholder="Maximum Amount" />
          </div>
        </div>
      </div>
      <div className="text-purple-500 text-base mt-3 mb-6 font-light uppercase">
        Target Details
      </div>
      <div>
        <div className="flex flex-wrap mt-10">
          <div className="w-full lg:w-4/12 md:pr-4 mb-10 font-light flex">
            <Input
              type="text"
              disabled={true}
              color="purple"
              value={`${users.isAllSelected ? "All" : users.selectedData.length
                } Users Selected`}
              placeholder="Users"
            />
            <div>
              <button
                onClick={() => {
                  selectTarget("users");
                }}
                className="bg-purple-500 hover:bg-purple-800 border border-purple-800 font-medium text-white text-sm p-1 px-4  focus:outline-none rounded-r-md"
              >
                Select
              </button>
            </div>
          </div>

          <div className="w-full lg:w-4/12 md:pr-4 mb-10 font-light flex">
            <Input
              type="text"
              disabled={true}
              color="purple"
              value={`${categories.isAllSelected
                ? "All"
                : categories.selectedData.length
                } Categories Selected`}
              placeholder="Categories"
            />
            <div>
              <button
                onClick={() => {
                  selectTarget("categories");
                }}
                className="bg-purple-500 hover:bg-purple-800 border border-purple-800 font-medium text-white text-sm p-1 px-4  focus:outline-none rounded-r-md"
              >
                Select
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-10 flex ">
        <button
          type="button"
          onClick={AddCoupon}
          class="py-3 px-4 text-sm font-medium text-center text-white bg-green-700 rounded-lg  hover:bg-green-500 focus:outline-none hover:ring-blue-300"
        >
          Submit
        </button>
        <button
          type="button"
          onClick={() => dispatch(clearDrawer())}
          class="mx-5 py-3 px-4 text-sm font-medium text-center text-white bg-red-700 rounded-lg  hover:bg-green-500 focus:outline-none hover:ring-blue-300"
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
